'use client';
import { Button, Link } from '@nextui-org/react';
import { useRouter } from 'next/navigation';

export function ParentButton({
  text,
  url,
  onClick,
  disabled,
  type,
  isLoading,
}: {
  text: string;
  url?: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit';
  isLoading?: boolean;
}) {
  const router = useRouter();
  url = url || '/';

  const props = type == 'submit' ? {} : onClick ? { onClick: onClick } : { as: Link, href: url };

  return (
    <div className="group relative flex place-self-center place-content-center border-2 border-black bg-light-steel-blue">
      <div className="my-px border-2 border-black  inset-0 absolute  group-hover:skew-x-2   group-hover:skew-y-2  group-hover:bg-dark-slate-blue"></div>

      <Button
        isDisabled={disabled}
        disableRipple={true}
        isLoading={isLoading}
        className="p-2  font-titillium-web min-w-max border-none bg-transparent  text-dark-slate-blue text-xl font-extrabold normal-case group-hover:z-10 group-hover:text-light-steel-blue"
        {...props}
        type={type || 'button'}
      >
        {text}
      </Button>
    </div>
  );
}
