'use client';
import { Button, Link } from '@nextui-org/react';
import { useRouter } from 'next/navigation';

export function FormButton({
  text,
  url,
  onClick,
  disabled,
  type,
  isLoading,
}: {
  text: string;
  url?: string;
  onClick?: () => void;
  disabled?: boolean;
  type?: 'button' | 'submit';
  isLoading?: boolean;
}) {
  const router = useRouter();
  const props = type == 'submit' ? {} : onClick ? { onClick: onClick } : { as: Link, href: url };

  return (
    <Button
      type={type || 'button'}
      isDisabled={disabled}
      isLoading={isLoading}
      disableRipple={true}
      className="p-3 border-3 border-black rounded-tl-3xl rounded-br-3xl rounded-tr-sm rounded-bl-sm bg-dark-slate-blue min-w-max place-self-center font-karla text-old-lace text-2xl leading-7 text-center font-medium hover:text-black hover:bg-light-steel-blue disabled:opacity-50"
      {...props}
    >
      {text}
    </Button>
  );
}
